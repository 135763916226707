@import "./variables.prvgld.scss";

.prvgld-form {
    .form-header {
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .form-control {
        font-size: 14px;
    }

    .datepicker {
        margin-right: 10px;
    }

    .custom-radio,
    .custom-checkbox {
        .custom-control-label {
            line-height: 29px;
        }
    }
    .row {
        margin-bottom: 10px;
    }

    label {
        font-weight: normal;
    }

    button {
        margin-top: 30px;
    }

    .prvgld-form-mandatoryfields {
        font-style: italic;
        margin-top: 20px;
    }

    .input-validation-error {
        border: 1px solid rgba(204, 0, 0, 1) !important;

        &:focus {
            border-color: rgba(204, 0, 0, 0.8) !important;
            box-shadow: 0 1px 1px rgba(204, 0, 0, 0.075) inset, 0 0 8px rgba(204, 0, 0, 0.6) !important;
            outline: 0 none !important;
        }
    }

    .field-validation-error {
        color: #cc0000;
        font-weight: normal;
    }
}
