@import "./variables.prvgld.scss";

.prvgld-subject-list {
    padding-left: 20px;
    padding-right: 20px;

    .subject-link {
        text-decoration: none;
        border-bottom: 0;
        padding-bottom: 3px;

        .subject-title {
            color: #1b143c;
            display: inline;

            @media only screen and (max-width: $max-width-mobile) {
                font-size: 18px;
                line-height: 25px;
            }
        }

        .subject-content {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 10px;
        }

        &:hover {
            .subject-title {
                color: #0066cc;
                border-bottom: 1px solid #0066cc;
            }

            .subject-content {
                color: #1b143c;
            }
        }

        &:before {
            font-family: "FontAwesome";
            content: "\f105 \0020";
            position: absolute;
            top: -2px;
            left: 0;
            font-size: 22px;

            @media only screen and (max-width: $max-width-mobile) {
                top: -4px;
            }
        }
    }
}
