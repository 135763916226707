$prvgld-color-primary: #1b143c;
$prvgld-color-header-text: #ffffff;
$prvgld-color-filter-background: #ebebeb;
$prvgld-color-footer-background: #ebebeb;

$max-width-mobile: 767px;
$min-width-tablet: 768px;
$max-width-tablet: 991px;
$min-width-desktop: 992px;
$max-width-table: 1200px;

@function grayscale($value) {
    @return #{ "grayscale(" + $value + ")" };
}