.prvgld-progress {
    display: flex;
    align-items: center;
    width: 100%;
    .progress {
        height: 9px;
        border-radius: unset;
        width: 100%;
        .progress-bar {
            background-color: #6ea64b !important;
        }
    }
    .progress-value {
        padding-left: 20px;
        white-space: nowrap;
        margin: 0;
        font-size: 13px;
    }
}
