.prvgld-modal {
    .modal-header {
        button:hover {
            background-color: rgba(255, 255, 255, 0); // Transparent background fix for hover on close button.
        }

        .close {
            padding-top: 12px;
        }
    }
}