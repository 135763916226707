
// // Required
// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";

// // Optional
// @import "../../node_modules/bootstrap/scss/reboot";
// @import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/code";
// @import "../../node_modules/bootstrap/scss/grid"; 

@import "./global.prvgld.scss";
@import "./button.prvgld.scss";
@import "./datatables.prvgld.scss";
@import "./filter.prvgld.scss";
@import "./footer.prvgld.scss";
@import "./form.prvgld.scss";
@import "./header.prvgld.scss";
@import "./menu.prvgld.scss";
@import "./popover.prvgld.scss";
@import "./table-list.prvgld.scss";
@import "./subject-list.prvgld.scss";
@import "./table.prvgld.scss";
@import "./progress.prvgld.scss";
@import "./progress-menu.prvgld.scss";
@import "./flag.prvgld.scss";
@import "./datepicker.prvgld.scss";
@import "./timepicker.prvgld.scss";
@import "./toptasks.prvgld.scss";
@import "./cards.prvgld.scss";
@import "./dialog.prvgld.scss";