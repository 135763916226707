@import "./variables.prvgld.scss";

.prvgld-table-list {
    vertical-align: middle;
    margin-top: 20px;
}

@media only screen and (max-width: $max-width-mobile) {
    .prvgld-table-list {
        margin-top: 10px;
    }
}

.prvgld-table-list hr {
    padding: 1px;
    margin: 0px 0px;
    border-color: #acacac;
    display: flex;
}

.prvgld-table-list .column-left {
    padding: 0px;
    padding-right: 20px;
    margin: 0px;
}

.prvgld-table-list .column-right {
    padding: 0px;
    margin: 0px;
    padding-left: 20px;
}

.prvgld-table-list .fa {
    position: relative;
    top: calc(50% - 10px);
}

@media only screen and (max-width: $max-width-mobile) {
    .prvgld-table-list .column-left {
        padding-right: 0px;
    }

    .prvgld-table-list .column-right {
        padding-left: 0px;
    }

    .prvgld-table-list .column-right .prvgld-table-list-header {
        margin-top: 30px;
    }
}

.prvgld-table-list a {
    border: none;
}

.prvgld-table-list a:hover {
    color: #0066cc;
}

.prvgld-table-list div {
    padding: 5px 0px;
}

.prvgld-table-list .fa {
    padding-top: 5px;
}

.prvgld-table-list a:hover {
    border-bottom: none !important;
}

.prvgld-table-list-header {
    font-weight: bold;
}
