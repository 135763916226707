@import "./variables.prvgld.scss";

.prvgld-button-primary {
    font-size: 20px;
    height: 60px;
    padding: 0px 30px;
    letter-spacing: 0.15px;
    box-shadow: 3px 3px #acacac;
    border: 0px;
    margin-top: 20px;
    cursor: pointer;

    @media only screen and (max-width: $max-width-tablet) {
        font-size: 17px;
        height: 45px;
        padding: 0px 15px;
    }
}

.prvgld-button-primary,
a.secondary,
.prvgld-button-secondary {
   
    &:hover, &:focus  {
        background-color: #ebebeb;
        color: #1b143c !important;  
    }

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(27, 20, 60, 0.5);
    }
}

a.secondary,
.prvgld-button-secondary {
    font-size: 17px;
    height: 45px;
    padding: 0px 15px;
    letter-spacing: 0.15px;
    box-shadow: 3px 3px #acacac;
    border: 0px;
    cursor: pointer;
}
