@import "./variables.prvgld.scss";

.prvgld-flag {
  width: 12px;

  * {
    height: 65px;
  }

  &__white{
    background-color: #FFFFFF;
  }

  &__blue {
    background-color: #11496A;
  }

  &__green {
    background-color: #3D9B35;
  }

  &__yellow {
    background-color: #F9DA0A;
  }

  &__black {
    background-color: #000000;
  }
}