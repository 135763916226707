@import "./variables.prvgld.scss";


.prvgld-footer {
    font-size: 14px;
    background-color: $prvgld-color-footer-background;
    line-height: 30px;
    padding-top: 30px;
    padding-left: 10px;
  
    a {
      text-decoration: none;
    }
  
    .no-underline {
      border-bottom: 0px;
    }
  
    h2 {
      font-family: 'Fedra Serif A Medium', Georgia, serif;
      color: #000000;
      font-size: 20px;
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    .logo {
      background-image: url('../images/logo.svg');
      background-repeat: no-repeat;
      background-size: auto 101px;
      background-position: right;
      height: 101px;
      margin-top: 60px;
      margin-bottom: 101px;
    }
  }
  
  @media only screen and (min-width: $min-width-tablet) and (max-width: $max-width-tablet){
    .prvgld-footer {
      margin-top: 70px;
    }
  }
  
  @media only screen and (max-width: $max-width-mobile) {
    .prvgld-footer {
      margin-top: 50px;
  
      .logo {
        height: 161px;
      }
  
      [class*=col] {
        padding-left: 5px;
        padding-right: 5px;
      }

      .container {
          max-width: none;
      }
    }
  }