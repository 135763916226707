@import "./variables.prvgld.scss";

.prvgld-table {
    font-family: "Fedra Serif A Book", Georgia, serif;
    font-size: 16px;
    color: $prvgld-color-primary;
    line-height: 26px;
    /* Non standard for webkit */
    word-break: normal;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    display: block;
    overflow-x: auto;
    border-collapse: collapse;
    margin-top: 20px;
    width: 100%;

    th {
        font-weight: bold;
        padding: 8px !important;
        vertical-align: top;
        word-break: break-word;
        word-wrap: break-word;
    }

    tr {
        padding: 8px;
    }

    td {
        border-right: 2px solid white;
        padding: 8px;
        vertical-align: top;
        word-break: break-word;
        word-wrap: break-word;
    }

    .odd {
        background: #ebebeb;
    }

    &[class$="-small-font"] {
        font-size: 13px;
        line-height: 22px;

        td {
            border-right: 1px solid white;
        }
    }

    @media only screen and (max-width: $max-width-table) {
        overflow-x: auto;
    }

    @media only screen and (max-width: $max-width-tablet) {
        overflow-x: auto;
    }

    @media only screen and (max-width: $max-width-mobile) {
        * {
            font-size: 12px;
            line-height: 22px;
        }
    }
}
