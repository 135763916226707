@import "./variables.prvgld.scss";


.prvgld-card {
    background-color: #F9F9F9;
    box-shadow: 1px 1px 2px #D9D9DE;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
    margin-bottom: 32px;

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0px;
    }

    @media only screen and (max-width: $max-width-tablet) {
        margin-bottom: 32px;
    }
}
