@import "./variables.prvgld.scss";

.prvgld-filter {
  background: $prvgld-color-filter-background;
  width: 100%;
  padding: 20px 35px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 26px;

  .button-icon {
    a {
      border-bottom: none;
    }

    &:hover {
      background-color: whitesmoke;
    }
  }

  .input-daterange {
    &-item {
      display: flex;
      flex-direction: row;
    }

    .datepicker {
      width: 140px;
      
      @media screen and (max-width: $max-width-mobile) {
        width: auto;
      }
    }
  }

  .filter-border-left {
    border-left: 1px solid grey;
  }
  
  .prvgld-filtergroep {
    width: 487px;
  }

  h2 {
    margin-top: 0px;
  }

  label {
    margin-bottom: 5px;
    justify-content: left;
  }

  input,
  .input-daterange-item,
  .input-group-append {
    height: 34px !important;
  }

  .input-group-addon {
    border-left: 0px;
  }

  .form-control {
    border-right: 0px;
  }

  .form-group {
    padding-left: 0;
  }

  a:visited {
    color: $prvgld-color-primary;
  }

  button {
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .input-group {
    margin-bottom: 0px !important;
  }

  @media screen and (max-width: $max-width-tablet) {

    margin-top: 30px;
  
    .input-group-btn {
      display: inline;
    }
  
    .input-group {
      padding-top: 10px;
    }

    .alert {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: $max-width-mobile) {
    
    margin-top: 20px;

    .form-control {
      display: block;
    }

    .input-daterange-item {
      margin-left: 10px;
    }

    .input-group {
      padding-top: 5px;
    }

    .filter-border-left {
      border-left: none;
    }

    .form-inline {
      display: inline-table;
      vertical-align: middle;
    }

    .alert {
      margin-right: 20px;
    }

    .input-daterange {
      display: flex;
      flex-direction: column;
    }
  }
}
