@import "./variables.prvgld.scss";

.prvgld-progress-menu {

    background-color: #eaeaea;   
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;

    .active, .disabled {
        padding-left: 28px;
    }

    .active {
        font-weight: 600;
    }

    .checked {
        padding-left: 5px;
        text-decoration: underline;
    }

    .disabled {
        color: $prvgld-color-primary;
        pointer-events: none;
        cursor: default;
    }

    a {
        border-bottom: none;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0;
        line-height: 25px;
        word-break: break-word;
    }

    .badge {
        font-size: 55%;
    }
}