@import "./variables.prvgld.scss";

@font-face {
    font-family: "Fedra Serif A Book";
    src: url("../fonts/FedraSerifA Book.eot"); /* IE9 Compat Modes */
    src: url("../fonts/FedraSerifA Book.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/FedraSerifA Book.woff") format("woff"),
        /* Pretty Modern Browsers */ url("../fonts/FedraSerifA Book.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/FedraSerifA Book.svg#svgFontName") format("svg"); /* Legacy iOS */
}

/* Regular */
@font-face {
    font-family: "Fedra Serif A Medium";
    src: url("../fonts/FedraSerifA Medium.eot"); /* IE9 Compat Modes */
    src: url("../fonts/FedraSerifA Medium.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/FedraSerifA Medium.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/FedraSerifA Medium.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/FedraSerifA Medium.svg#dbef079a62cfeea604206708d0e34c96") format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: 400;
}

/* Regular Italic */
@font-face {
    font-family: "Fedra Serif A Medium Italic";
    src: url("../fonts/FedraSerifA Medium Italic.eot"); /* IE9 Compat Modes */
    src: url("../fonts/FedraSerifA Medium Italic.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/FedraSerifA Medium Italic.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/FedraSerifA Medium Italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/FedraSerifA Medium Italic.svg#dbef079a62cfeea604206708d0e34c96")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: 400;
}

/* Bold Italic */
@font-face {
    font-family: "Fedra Serif A Medium Italic Bold";
    src: url("../fonts/FedraSerifA Medium Italic.eot"); /* IE9 Compat Modes */
    src: url("../fonts/FedraSerifA Medium Italic.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/FedraSerifA Medium Italic.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/FedraSerifA Medium Italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/FedraSerifA Medium Italic.svg#bbd9b87bdb9ff2d3043e4b4c34a1bb43")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: 700;
}

html,
body {
    overflow-x: hidden;
    min-height: 500px;
}

body {
    /* desktop en tablet */
    font-family: "Fedra Serif A Book", Georgia, serif;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 32px;
    color: $prvgld-color-primary;
}

@media only screen and (max-width: $max-width-mobile) {
    body {
        /* mobiele telefoon */
        font-size: 16px;
        line-height: 28px;
    }
}

h1 {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    font-size: 42px;
    color: $prvgld-color-primary;
    line-height: 48px;
    margin-top: 50px;
}

h2 {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    font-size: 22px;
    color: $prvgld-color-primary;
    line-height: 32px;
}

h3 {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    font-size: 17px;
    color: $prvgld-color-primary;
    line-height: 32px;
}

@media only screen and (max-width: $max-width-tablet) {
    h1 {
        margin-top: 30px;
    }
}

@media only screen and (max-width: $max-width-mobile) {
    h1 {
        font-size: 30px;
        line-height: 40px;
        margin-top: 20px;
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
    }

    h3 {
        font-size: 15px;
        line-height: 28px;
    }
}

h1 + p,
h1 + div {
    margin-top: 30px;
}

@media only screen and (max-width: $max-width-tablet) {
    h1 + p,
    h1 + div {
        margin-top: 25px;
    }
}

@media only screen and (max-width: $max-width-mobile) {
    h1 + p,
    h1 + div {
        margin-top: 15px;
    }
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

.form-control {
    box-shadow: none;
    border-radius: 0px !important;
}

.input-group-addon {
    border-radius: 0px;
}

/*Fix for Firefox*/
.input-group-btn button:focus {
    outline: 0;
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

ul {
    padding-left: 15px;
}

.container {
    padding: 0px;
}

button {
    &:focus {
        outline: 2px auto -webkit-focus-ring-color;
    }
}

@media only screen and (max-width: $max-width-mobile) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

hr {
    border-top: 1px solid #acacac;
    margin-top: 50px;
    margin-bottom: 30px;
}

@media only screen and (min-width: $min-width-tablet) and (max-width: $max-width-tablet) {
    hr {
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: $max-width-mobile) {
    hr {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

fieldset legend {
    display: none;
}

a {
    color: $prvgld-color-primary;
    text-decoration: none;
    border-bottom: 1px solid #747474;
}

a:hover {
    color: #0066cc !important;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
}

a:visited {
    text-decoration: none;
}

button {
    height: 34px;
}

/* styling icons in textboxes */
.button-icon {
    background-color: white;
    border: none;
    color: $prvgld-color-primary;
    padding-top: 8px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 22px;
}

.button-icon span {
    font-size: 14px;
}

.prvgld-home-picture {
    background-image: url("../images/header.jpg");
    background-color: $prvgld-color-primary;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 480px;

    @media only screen and (min-width: $min-width-tablet) and (max-width: $max-width-tablet) {
        height: 275px;
    }

    @media only screen and (max-width: $max-width-mobile) {
        height: 250px;
    }
}

.prvgld-home-picture .container {
    height: inherit;

    @media only screen and (max-width: $max-width-mobile) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.prvgld-home-picture .prvgld-home-header-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: inherit;
    width: 100%;
}

.prvgld-home-header {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    color: $prvgld-color-header-text;
    font-size: 62px;
    line-height: 70px;
    text-shadow: 4px 4px 9px rgba(0, 0, 0, 0.5);
    padding-bottom: 40px;

    @media only screen and (min-width: $min-width-tablet) and (max-width: $max-width-tablet) {
        font-size: 42px;
        line-height: 48px;
        padding-bottom: 30px;
    }

    @media only screen and (max-width: $max-width-mobile) {
        font-size: 25px;
        line-height: 30px;
        padding-bottom: 20px;
    }
}

a.btn {
    border-radius: 0px;
    padding-top: 10px !important;
}

a.btn,
button {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    color: #ffffff;
    background-color: $prvgld-color-primary;
}

a.btn:hover,
button:hover {
    background-color: #ebebeb;
    color: $prvgld-color-primary !important;
}

button:active {
    outline: 0;
}

.prvgld-home .prvgld-lead {
    margin-top: 50px;

    @media only screen and (min-width: $min-width-tablet) and (max-width: $max-width-tablet) {
        margin-top: 30px;
    }

    @media only screen and (max-width: $max-width-mobile) {
        margin-top: 20px;
    }
}

.prvgld-lead {
    font-family: "Fedra Serif A Medium", Georgia, serif;
    color: $prvgld-color-primary;
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 20px;

    @media only screen and (max-width: $max-width-mobile) {
        font-size: 16px;
        line-height: 28px;
    }
}

.prvgld-small {
    font-family: "Fedra Serif A Book", Georgia, serif;
    color: $prvgld-color-primary;
    font-size: 16px;
    line-height: 26px;

    @media only screen and (max-width: $max-width-mobile) {
        font-size: 14px;
        line-height: 22px;
    }
}

.nopadding {
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.input-group-addon {
    color: $prvgld-color-primary;
    background-color: white;
}

/* broodkruimel */
.prvgld-breadcrumb {
    font-family: "Fedra Serif A Book", Georgia, serif;
    color: $prvgld-color-primary;
    font-size: 12px;
}

.prvgld-breadcrumb a {
    text-decoration: none;
    border-bottom: 1px solid #747474;
}

.prvgld-breadcrumb a:hover {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
}

@media only screen and (max-width: $max-width-mobile) {
    .prvgld-breadcrumb {
        font-size: 11px;
        margin-top: 10px;
    }
}

.skip {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

a.skip:focus,
a.skip:active,
a.skip:hover {
    position: static;
    width: auto;
    height: auto;
}

.nowrap {
    white-space: nowrap;
}

/* placeholder */
::-webkit-input-placeholder {
    color: #555555 !important;
}

::-moz-placeholder {
    color: #555555 !important;
}

::-ms-placeholder {
    color: #555555 !important;
}

::placeholder {
    color: #555555 !important;
}

* input, .input-group-text, .form-control, .custom-control-input ~ .custom-control-label::before  {
    border-color: #929494;
}