@import "./variables.prvgld.scss";

$grayLight: rgb(114, 114, 114);
$white: #fff;
$grayLighter: rgb(204, 199, 199);
$primaryBackground: $prvgld-color-primary;
$infoBackground: $prvgld-color-primary;
$todayBackground: rgb(25, 135, 199);
$baseLineHeight: 1px;

.datepicker {
    width: 280px;
    &-inline {
        width: 220px;
    }
    direction: ltr;
    &-rtl {
        direction: rtl;
        &.dropdown-menu {
            left: auto;
        }
        table tr td span {
            float: right;
        }
    }
    .input-group-append {
        cursor: pointer;
        &.disabled:hover {
            cursor: default;
        }
      }

    &-dropdown {
        margin-top: 9px;
        &:before {
            content: "";
            display: inline-block;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $grayLight;
            border-top: 0;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            position: absolute;
        }
        &:after {
            content: "";
            display: inline-block;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid $white;
            border-top: 0;
            position: absolute;
        }
        &.datepicker-orient-left:before {
            left: 16px;
        }
        &.datepicker-orient-left:after {
            left: 17px;
        }
        &.datepicker-orient-right:before {
            right: 6px;
        }
        &.datepicker-orient-right:after {
            right: 7px;
        }
        &.datepicker-orient-bottom:before {
            top: -10px;
        }
        &.datepicker-orient-bottom:after {
            top: -9px;
        }
        &.datepicker-orient-top:before {
            bottom: -7px;
            border-bottom: 0;
            border-top: 7px solid $grayLight;
        }
        &.datepicker-orient-top:after {
            bottom: -6px;
            border-bottom: 0;
            border-top: 6px solid $white;
        }
    }
    table {
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    td,
    th {
        text-align: center;
        width: 40px;
        height: 20px;
        border-radius: 4px;

        border: none;
    }
    // Inline display inside a table presents some problems with
    // border and background colors.
    .table-striped & table tr {
        td,
        th {
            background-color: transparent;
        }
    }
    table tr td {
        &.day:hover,
        &.day.focused {
            background: $grayLighter;
            cursor: pointer;
        }
        &.old,
        &.new {
            color: $grayLight;
        }
        &.disabled,
        &.disabled:hover {
            background: none;
            color: $grayLight;
            cursor: default;
        }
        &.highlighted {
            background: $infoBackground;
            border-radius: 0;
        }
        &.today,
        &.today:hover,
        &.today.disabled,
        &.today.disabled:hover {
            border-style: solid;
            border-color: $primaryBackground;
            border-width: thin;
            color: #000;
        }
        &.today:hover:hover {
            // Thank bootstrap 2.0 for this selector...
            // TODO: Bump min BS to 2.1, use $textColor in buttonBackground above
            color: #000;
        }
        &.today.active:hover {
            color: #fff;
        }
        &.range,
        &.range:hover,
        &.range.disabled,
        &.range.disabled:hover {
            background-color: $grayLighter;
            border-radius: 0;
        }
        &.selected,
        &.selected:hover,
        &.selected.disabled,
        &.selected.disabled:hover {
            background-color: lighten($primaryBackground, 10%);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
        &.active,
        &.active:hover,
        &.active.disabled,
        &.active.disabled:hover {
            color: #fff;
            background-color: lighten($primaryBackground, 10%);
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
        span {
            display: block;
            width: 23%;
            height: 54px;
            line-height: 54px;
            float: left;
            margin: 1%;
            cursor: pointer;
            border-radius: 4px;
            &:hover,
            &.focused {
                background: $grayLighter;
            }
            &.disabled,
            &.disabled:hover {
                background: none;
                color: $grayLight;
                cursor: default;
            }
            &.active,
            &.active:hover,
            &.active.disabled,
            &.active.disabled:hover {
                background-color: $primaryBackground;
                color: #fff;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
            }
            &.old,
            &.new {
                color: $grayLight;
            }
        }
    }

    .datepicker-switch {
        width: 145px;
    }

    .datepicker-switch,
    .prev,
    .next,
    tfoot tr th {
        cursor: pointer;
        &:hover {
            background: $grayLighter;
        }
    }

    .prev,
    .next {
        &.disabled {
            visibility: hidden;
        }
    }

    // Basic styling for calendar-week cells
    .cw {
        font-size: 10px;
        width: 12px;
        padding: 0 2px 0 5px;
        vertical-align: middle;
    }
    
}