@import "./variables.prvgld.scss";

.toptasks {
    .action {
        margin-bottom: 26px;

        @media only screen and (max-width: $max-width-mobile) {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .action-button {
            font-family: "Fedra Serif A Medium", Georgia, serif;
            display: inline-block;
            width: 100%;
            background-color: #1b143c;
            color: #ffffff;
            text-align: center;
            font-size: 22px;
            box-shadow: 3px 3px #acacac;
            height: 100%;
            line-height: 75px;
            text-decoration: none;
            border-bottom: 0;
            letter-spacing: 1px;

            &:hover, &:focus  {
                background-color: #ebebeb;
                color: #1b143c !important;
            }

            &:active {
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(27, 20, 60, 0.5);
            }

            @media only screen and (max-width: $max-width-mobile) {
                line-height: 60px;
            }

            span {
                vertical-align: middle;
                line-height: 30px;
                display: inline-block;

                @media only screen and (max-width: $max-width-mobile) {
                    line-height: 22px;
                }
            }
        }
    }
}
