@import "./variables.prvgld.scss";

.navbar-default {
  background-color: $prvgld-color-primary;
}

.navbar-toggler {
  width: 100%;
  height: 100%;
  border-radius: 0;
  outline-width: 1px;
  border-right: 2px solid #ebebeb !important;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;

  .navbar-toggler-icons-container {

    @media (max-width: $max-width-tablet) {
      width: 35px;
      text-align: left;
    }

    @media only screen and (max-width: $max-width-mobile) {
      width: auto;
      text-align: center;
    }
  }
}

.navbar-toggler.collapsed {
  .close-menu {
    display: none;
  }
}

.navbar-toggler:not(.collapsed) {
  .navbar-menu & {
    background: #ebebeb;
  }

  .navbar-search & {
    background: #ebebeb;
  }

  .toggler-icon-trigger {
    display: none;
  }
}

.navbar {
  height: 60px;
  width: 100% !important;
  background-color: $prvgld-color-primary;
  margin-bottom: 0px;
  border-bottom: 0px;
  padding: 0;
}

.navbar-toggler {
  color: $prvgld-color-primary !important;
}

@media screen and (max-width: $max-width-tablet) {
  .navbar .container {
    margin-left: 0;
    margin-right: 0;
    min-width: 100%;
  }
}

@media screen and (max-width: $max-width-tablet) {
  .navbar {
    background-color: white;
  }
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar {
    height: 35px;
    min-height: 0px;
  }

  .navbar-default {
    background-color: none;
  }
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar-toggle {
    height: 35px;
    margin-left: 0px;
  }

  .navbar-menu .navbar-toggle {
    padding-left: 10px;
    padding-right: 5px;
  }

  .navbar-search .navbar-toggle {
    padding-left: 15px;
    padding-right: 10px;
  }
}

@media (min-width: $max-width-mobile) and (max-width: $max-width-tablet) {
  .navbar .navbar-nav {
    flex-direction: row !important;
  }

  .navbar-toggler {
    display: flex !important;
  }

  .navbar-menu {
    .fa {
      margin-top: 15px;
    }

    .navbar-text {
      margin-top: 15px;
    }
  }

  .navbar-search {
    .fa {
      padding-left: 3px;
    }

    .close-menu {
      padding-left: 7px;
    }
  }
}

.navbar-default .navbar-toggle.collapsed {
  background-color: white !important;
}

.navbar-default .navbar-toggle {
  background-color: #ebebeb !important;
}

@media (max-width: $max-width-tablet) {
  .navbar-collapse.collapse.show,
  .navbar-collapse.collapsing {
    z-index: 1000;
    background-color: #ebebeb;
    padding-bottom: 15px;
    .form-inline {
      padding-top: 20px;
    }
  }
}

.navbar-header {
  background-color: white;
  display: flex;
  border-bottom: 2px solid #ebebeb;
  flex: 0 0 100%;
  margin-bottom: -3px;
}

@media only screen and (min-width: $min-width-desktop) {
  .navbar-header {
    display: none;
  }
}

.navbar-header .fa {
  font-size: 24px;
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar-header .fa {
    font-size: 16px;
  }
}

.navbar-header-center {
  text-align: center;
  flex: 1 auto;
}

.navbar-header h1 {
  font-size: 23px !important;
  display: inline-block;
  line-height: normal;
  margin-top: 15px;
}

.navbar-header h1 a {
  font-size: 23px !important;
  text-decoration: none;
  border-bottom: none;
  color: inherit !important;
}

.navbar-header h1 a:hover {
  text-decoration: none;
  border-bottom: none;
  color: inherit !important;
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar-header h1 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .navbar-header h1 a {
    font-size: 16px !important;
  }
}

.navbar-close {
  float: left;
}

/* Tekst naast hamburger menu */
.navbar-text {
  color: $prvgld-color-primary;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  letter-spacing: 3px;
  padding-top: 8px;
  padding-left: 3px;
  padding-bottom: 0;
}

.navbar .navbar-nav {
  font-size: 0; /* ik moest dit toevoegen, anders krijg je ruimte tussen de li elementen: https://stackoverflow.com/questions/5256533/a-space-between-inline-block-list-items */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar .navbar-nav li {
  font-family: "Fedra Serif A Medium", Georgia, serif;
  font-size: 16px; /* zie comment hierboven */
  color: $prvgld-color-primary;
  display: inline;
  margin-left: 0px;
  margin-right: 0px;
}
@media screen and (min-width: $min-width-desktop) {
  .navbar {
    height: 60px;
  }

  .navbar .navbar-nav {
    height: 60px;
  }

  .navbar .navbar-nav li {
    height: 60px;
  }

  .navbar .navbar-nav li a {
    height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  //   .navbar-collapse.collapse {
  //     height: 60px !important;
  //   }
}

@media screen and (max-width: $max-width-tablet) {
  .navbar .navbar-nav {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }

  .navbar .navbar-nav li {
    display: block;
  }

  .navbar .navbar-nav li a,
  .navbar .navbar-nav li a:visited {
    color: $prvgld-color-primary !important;
    padding: 5px 15px;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0px;
    line-height: 32px;
  }

  .navbar .navbar-nav li i {
    margin-right: 5px;
  }
}

@media screen and (max-width: $max-width-mobile) {
  .navbar .navbar-nav {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .navbar .navbar-nav li {
    display: block;
  }
}

.navbar .navbar-nav li a,
.navbar navbar-nav li a:visited {
  color: white;
  border-bottom: 0px;
}

.navbar .navbar-nav li a.active,
.navbar .navbar-nav li a:hover,
.navbar .navbar-nav li a:focus {
  background-color: #ebebeb !important;
  color: $prvgld-color-primary !important;
  text-decoration: none !important;
  border-bottom: 0px !important;
}

/* Knop in menu balk */
.navbar ul li button {
  font-family: "Fedra Serif A Medium", Georgia, serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #fff;
  background-color: $prvgld-color-primary;
  height: 35px;
  border: 1px;
  border-color: #fff;
  border-style: solid;
  padding: 0px 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 12px;
  margin-bottom: 0px;
  display: inline-block;
  line-height: 32px;
}

.navbar ul li button:hover,
.navbar ul li button:focus {
  background-color: #fff;
  color: $prvgld-color-primary;
}

.navbar {
  ul {
    li {
      button {
        &:hover {
          background-color: #fff;
          color: $prvgld-color-primary;
        }
      }
    }
  }
}

/*Fix for Firefox*/
.navbar ul li button:focus {
  outline: 1px dotted rgb(77, 144, 254);
}

@media screen and (max-width: $max-width-tablet) {
  .navbar ul li button,
  .navbar ul li button:hover,
  .navbar ul li button:focus,
  .navbar ul li button:active {
    background-color: #ebebeb;
    color: $prvgld-color-primary;
    border-color: $prvgld-color-primary;
    margin-left: 15px;
    margin-top: 0px;
    padding-left: 15px;
  }
}

@media screen and (max-width: $max-width-mobile) {
  .navbar ul li button,
  .navbar ul li button:hover,
  .navbar ul li button:focus,
  .navbar ul li button:active {
    margin-top: 10px;
    margin-left: 5px;
  }
}

.navbar-grayout {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray; /* IE 6-9 */
}

.navbar-grayout:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.3;
}

.navbar-search button {
  width: 60px;
  border-left: 2px solid #ebebeb !important;

  @media only screen and (max-width: $max-width-tablet) {
    padding-top: 20px;
  }

  @media only screen and (max-width: $max-width-mobile) {
    padding-top: 0;
  }
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar-search {
    height: 35px;
  }

  .navbar-collapse-search {
    .input-group {
      padding: 0px 10px;

      a {
        &:hover {
          border-bottom: 0;
        }
      }
    }
  }
}

.navbar-collapse-search {
  background-color: #ebebeb;
  padding-bottom: 0px;
  z-index: 1000;
}

.navbar-collapse-search input {
  width: 100%;
}

@media only screen and (max-width: $max-width-tablet) {
  .navbar-collapse-search .form-group,
  .navbar-collapse-search .input-group {
    width: 100%;
  }
}

@media screen and (min-width: $min-width-desktop) {
  .navbar-collapse-search.navbar-collapse.collapse {
    display: none !important;
  }
}

.navbar-column {
  float: left;
}

.navbar-header:after {
  content: "";
  display: table;
  clear: both;
}

.navbar-menu {
  width: 150px;
}

@media only screen and (max-width: $max-width-mobile) {
  .navbar-menu {
    width: 50px;
  }
}

/* Submenu's */
.navbar-nav li .dropdown-menu {
  padding: 0px;
  background-color: #1b143c;
  margin-top: 2px !important;
  margin-left: 15px;
  border: none;
}

.navbar-nav li .dropdown-menu ul {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-top: 2px !important;
}

.navbar-nav li .dropdown-menu li {
  color: rgb(255,255,255) !important;
  background-color: #1b143c;
  height: 51px !important;
  box-sizing: border-box;
  display: list-item !important;
  width: auto;
}

.navbar-nav li .dropdown-menu li a {
  background-color: #1b143c !important;
  height: inherit !important;
  display: inline-block;
  width: 100% !important;
  padding-top: 10px;
}

@media only screen and (max-width: 991px) {
  .navbar .navbar-nav li {
      display: inline-block !important;
  }

  .navbar-nav li .dropdown-menu li {
      background-color: transparent !important;
      height: auto !important;
  }

  .navbar-nav li .dropdown-menu li a,
  .navbar-nav li .dropdown-menu li a:visited {
      background-color: #ebebeb !important;
      color: #747474 !important;
      height: auto !important;
  }

  .navbar-nav li .dropdown-menu li a:hover,
  .navbar-nav li .dropdown-menu li a:focus {   
      color: #676767 !important;
      background-color: #f5f5f5 !important;
  }

  .navbar .navbar-nav li a:hover,
  .navbar .navbar-nav li a:focus {
    color: #0066cc !important;
  }
}

@media only screen and (max-width: 767px) {
  .navbar .navbar-nav li {
      display: block !important;
  }
}