@import "./variables.prvgld.scss";


/* Sorteren */
table.dataTable thead th {
	position: relative;
	background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
	content: " ";
	position: absolute;
	top: 6px;
	right: 8px;
	display: block;
	font-family: FontAwesome;
	color: $prvgld-color-primary !important;
}

table.dataTable thead th.sorting_asc:after {
	content: "\f106";
	color: $prvgld-color-primary;
	opacity: 1;
}

table.dataTable thead th.sorting_desc:after {
	content: "\f107";
	color: $prvgld-color-primary;
	opacity: 1;
}

/* Horizontale lijn */
.dataTables_wrapper .hr {
    margin-top: 20px;
    margin-bottom: 50px;
    border: 0;
    border-top: 1px solid #acacac;
}

@media only screen and (max-width: $max-width-mobile) {
    .dataTables_wrapper .hr {
        margin-bottom: 20px;
    }
}

/* Bezig icoon naar voorgrond brengen */
.dataTables_processing {
    z-index: 1000;
}

/* Paginering */
div.dataTables_paginate  {
    text-align: center !important;
}

.pagination {
    font-family: 'Fedra Serif A Medium', Georgia, serif;
    font-size: 22px;
    color: $prvgld-color-primary;
}

@media screen and (max-width: $max-width-tablet) {
    .pagination {
        font-size: 18px;
    }
}

@media only screen and (max-width: $max-width-mobile) {
    .pagination {
        font-size: 16px;
    }
}

.pagination a {
    text-decoration: none !important;
    border-bottom: 1px solid #747474 !important;
    padding-bottom: 1px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.pagination a:hover {
    color: #0066cc !important;
}

.pagination .active a {
    border-bottom: 0px !important;
}

.pagination > li.previous > a {
    border: none !important;
}
.pagination > li.next > a {
    border: none !important;
}

.pagination > li.disabled > a { /* paginering - ellipsis */
    border: none !important;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:hover {
    color: #0066cc;
    background-color: #ffffff;
    border: none;
    text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
	border: none;
	color: $prvgld-color-primary;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > span,
.pagination > li > span:hover {
	background-color: #ffffff;
	border: none;
}

.pagination > active > a,
.pagination > active > a:hover,
.pagination > active > span,
.pagination > active > span:hover {
	background-color: #ffffff;
	border: none;
}

.pagination .fa {
	text-decoration: none;
}

/* Info boven tabel */
.dataTables_info {
    text-align: left !important;
    margin-bottom: 20px;
}

.dataTables_info h2 {
    display: inline;
}

/* Verberg buttons op smartphone */
@media only screen and (max-width: $max-width-mobile) {
    div.dt-buttons {
        display: none !important;
    }
}