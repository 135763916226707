@import "./variables.prvgld.scss";

.prvgld-header {
  height: 60px;
  background: #ffffff;

  input {
    padding-bottom: 1px;
  }

  h1 {
    font-size: 23px;
    display: inline-block;
    line-height: normal;
    margin-top: 13px;

    a {
      text-decoration: none;
      border-bottom: none;
      color: inherit !important;

      &:hover {
        text-decoration: none;
        border-bottom: none;
        color: inherit !important;
      }
    }
  }

  .button-icon {
    a {
      text-decoration: none;
      border-bottom: none;
      color: inherit !important;
      border-bottom: none;
    }
  }

  .header-search {
    margin-top: 3px;
  }
  
  .header-search .input-group-btn {
    border-left: 0px;
  }
  
  .header-search .form-control {
    border-right: 0px;
  }

  .subheader {
      font-family: "Fedra Serif A Book", Georgia, serif;
      font-size: 16px;
      line-height: normal;
      display: inline-block;
      margin-left: 20px;
  }
}

.navbar-collapse-search input {
  font-size: 14px;
  height: 34px;
}

.navbar-collapse-search button {
  font-size: 14px;
  padding-top: 3px;
}

@media only screen and (max-width: $min-width-tablet) {
  .prvgld-header {
    height: 30px;
  }

  .prvgld-header h1 {
    font-size: 16px;
  }
}
