@import "./variables.prvgld.scss";

.popover {
    font-family: 'Fedra Serif A Book', Georgia, serif;
    font-size: 13px;
    color: $prvgld-color-primary;
    width: 300px;
    border-radius: 0 !important;
}

.popover-header {
    background-color: white;
    border-bottom: none;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
}

.popover-body {
    word-wrap: break-word;
}

[data-toggle="popover"] {
    cursor: pointer;
}